import React, { useEffect, useState } from "react"
import Title from "../components/shared-title"
import loginBg from "../images/login.jpg";
import useStores from "../hooks/use-stores"
import { observer } from "mobx-react";
import { navigate } from "gatsby";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import VmButton from "../components/shared-button";
import { isBrowser, Language, NavListByCategory, Notify, ProdEnvCheck } from "../constants/options";
import { useTranslation } from "react-i18next";

const IndexPage = observer(() => {
  const { userStore, rootStore } = useStores();
  const { t } = useTranslation();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  useEffect(() => {
    if (userStore.isAuthed && userStore.userToken !== "" && localStorage.USER_TOKEN) {
      if (isBrowser && ProdEnvCheck([localStorage.MENU])) navigate(NavListByCategory[localStorage.MENU][0] ? NavListByCategory[localStorage.MENU][0].link : `/${localStorage.MENU.toLowerCase()}`);
      else navigate("/dashboard");
      rootStore.notify(
        t('HI') + `, ${isBrowser && ProdEnvCheck([localStorage.USER_INFO]) && JSON.parse(localStorage.USER_INFO).firstName}`, 'success'
      );
    }
  }, []);

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      signIn();
    }
  };

  const signIn = () => {
    userStore.signIn({
      username,
      password,
    }).then(() => {
      delete localStorage.MENU;
      navigate("/dashboard");
    }).catch(() => {
      rootStore.notify(t('INCORRECT_USERNAME_PASSWORD'), 'error');
    })
  }


  return (
      <>
        <Title pageName={t('SIGN_IN')} />
        <div className="top-0 left-0 right-0 bottom-0 h-screen relative bg-sky-500">
          <div
            className="absolute top-1/2 left-1/2"
            style={{ transform: 'translate(-50%, -50%)', width: 'fit-content' }}
          >
            {
              userStore.isAuthed && userStore.userToken !== "" && localStorage.USER_TOKEN ?
                <div className="bg-white p-8 rounded-lg">
                  <Typography variant="h6">{t('VALIDATING_USER_CREDENTIAL')}...</Typography>
                </div>
                :
                <div
                  className="relative border border-white overflow-auto shadow-2xl"
                  style={{ background: "rgba(255, 255, 255, 0.3)" }}
                >
                  <div className="grid grid-cols-2 bg-white m-4">
                    <div className="relative ml-4">
                      <img
                        src={loginBg}
                        className="absolute top-1/2 left-1/2 object-cover rounded-full"
                        style={{ transform: 'translate(-50%, -50%)', width: isBrowser && innerWidth > 1536 ? 320 : 270, height: isBrowser && innerWidth > 1536 ? 320 : 270 }}
                      />
                    </div>
                    <div className="p-8 border border-l-0">
                    <div className="lg:text-xl 2xl:text-2xl text-sky-500 text-center mb-4">{t('SIGN_IN_NOW')}</div>
                      <div className={`text-gray-400 lg:text-xs 2xl:text-sm text-center lg:mb-6 2xl:mb-8 ${isBrowser && ProdEnvCheck([localStorage.LANG]) && localStorage.LANG !== Language.ENGLISH && 'px-20'}`}>
                        {t('WELCOME_TO_AUOKKA')}
                      </div>

                      <div className="my-4">
                        <TextField
                          id="login-username"
                          value={username}
                          label={t('USERNAME')}
                          variant="outlined"
                          className="w-full"
                          onKeyDown={handleKeyPress}
                          onChange={(value) => setUsername(value.target.value)}
                        />
                      </div>
                      <div className="my-4">
                        <TextField
                          id="login-password"
                          value={password}
                          label={t('PASSWORD')}
                          type={"password"}
                          variant="outlined"
                          className="w-full"
                          onKeyDown={handleKeyPress}
                          onChange={(value) => setPassword(value.target.value)}
                        />
                      </div>
                      {
                        userStore.loading ? <div className="text-center py-4"><CircularProgress className="text-sky-500" /></div>
                          : <VmButton
                            className={`w-full mt-8 ${userStore.loading ? "bg-white" : "py-2 bg-sky-500"} text-lg`}
                            onClick={signIn}
                            disabledElevation={userStore.loading}
                            type={'submit'}
                          >
                            <div className="text-white">
                              {t('SIGN_IN')}
                            </div>
                          </VmButton>
                      }
                    </div>
                  </div>

                </div>
            }
          </div>
        </div>
      </>
  )
})

export default IndexPage;
