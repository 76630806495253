import * as React from "react"
import useStores from "../hooks/use-stores"

// markup
const VmButton = ({
  onClick = null, className = "", disabled = false, disabledElevation = false, style = null, children, type
}) => {
  return (
    <button
      type="button"
      style={style}
      className={`${disabled && "disabled-button"} ${className} rounded-lg ${!disabledElevation && "shadow-2xl"} hover:shadow-sm`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default VmButton;
